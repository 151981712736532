<script setup>
    import { ref } from 'vue';
    import { getStudentLet, getStudentNum, CLASS_LET, CLASS_NUM, LET_NAMES, LET_COLORS } from '@/components/const'
    import { httpGet } from './func/request';
// import AlertMessage from './ui/AlertMessage.vue';
CLASS_LET.reverse()
    let rawRecords = (await httpGet('records/list_rating.php',true,[])).data
    const ratingByNumbers = {
        'c8':  {'П': 0, 'Р': 0, 'К': 0, 'М': 0, 'Б': 0},
        'c9':  {'П': 0, 'Р': 0, 'К': 0, 'М': 0, 'Б': 0},
        'c10': {'П': 0, 'Р': 0, 'К': 0, 'М': 0, 'Б': 0},
        'c11': {'П': 0, 'Р': 0, 'К': 0, 'М': 0, 'Б': 0}
    }
    let sortedRatingByNumbers = {
        'c8':  CLASS_LET,
        'c9':  CLASS_LET,
        'c10': CLASS_LET,
        'c11': CLASS_LET
    }
    const sortBy = ref('byStudents')
    const sortNumBy = ref(8)
    let records = {}
    let sortedRecords = {}
    let gradeOfStudents = {}

    for (let record of rawRecords) {
        let rName = record.studentName
        let rGrade = record.studentGrade
        if (records[rName] != undefined) {
            ratingByNumbers[`c${getStudentNum(rGrade)}`][getStudentLet(rGrade)]++
            records[rName].count++
            sortedRecords[rName]++
        } else {
            ratingByNumbers[`c${getStudentNum(rGrade)}`][getStudentLet(rGrade)]++
            sortedRecords[rName] = 1
            records[rName] = {count: 1, grade: rGrade}
        }
        if (!gradeOfStudents[rName]) gradeOfStudents[rName] = rGrade
    }

    const ratingByLets = {
        'Р': ratingByNumbers['c8']['Р']+ratingByNumbers['c9']['Р']+ratingByNumbers['c10']['Р']+ratingByNumbers['c11']['Р'],
        'К': ratingByNumbers['c8']['К']+ratingByNumbers['c9']['К']+ratingByNumbers['c10']['К']+ratingByNumbers['c11']['К'],
        'М': ratingByNumbers['c8']['М']+ratingByNumbers['c9']['М']+ratingByNumbers['c10']['М']+ratingByNumbers['c11']['М'],
        'Б': ratingByNumbers['c8']['Б']+ratingByNumbers['c9']['Б']+ratingByNumbers['c10']['Б']+ratingByNumbers['c11']['Б'],
        'П': ratingByNumbers['c8']['П']+ratingByNumbers['c9']['П']+ratingByNumbers['c10']['П']+ratingByNumbers['c11']['П'],
    }
    for (let num of CLASS_NUM) {
        sortedRatingByNumbers[`c${num}`] = Object.keys(ratingByNumbers[`c${num}`]).sort(function(a,b){return ratingByNumbers[`c${num}`][a]-ratingByNumbers[`c${num}`][b]}).reverse()
    }
    const recordGrades = {}
    for (let num of CLASS_NUM) {
        for (let lett of CLASS_LET) {
            recordGrades[`${num}${lett}`] = ratingByNumbers[`c${num}`][lett]
        }
    }
    let sortedGrades = Object.keys(recordGrades).sort(function(a,b){return recordGrades[a]-recordGrades[b]}).reverse()
    let sortedLets = Object.keys(ratingByLets).sort(function(a,b){return ratingByLets[a]-ratingByLets[b]}).reverse()
    sortedRecords = Object.keys(sortedRecords).sort(function(a,b){return sortedRecords[a]-sortedRecords[b]}).reverse()
</script>

<template>
<div class="p-3">
    <h1 class="text-body text-center mb-4 bt-3">Рейтинг <span class="badge bg-secondary">Предварительно</span></h1>
    <p class="text-body text-center mb-4 bt-3">Это предварительные данные, точный рейтинг будет доступен только после проверки всех записей КПД учеников.</p>
    <!-- <Suspense>
            <AlertMessage/>
    </Suspense> -->
    <!-- <div class="card mb-3">
        <div class="card-body"> -->
            <div class="row row-cols-1 row-cols-md-3 g-3 mx-auto">
                <div class="col">
                    <div :class="`card text-bg-${rawRecords.length > 0 ? LET_COLORS[getStudentLet(gradeOfStudents[sortedRecords[0]])] : 'primary'} mb-3`">
                    <div class="card-header">
                        <h4 v-if="rawRecords.length > 0"><i class="bi bi-person-check"></i> {{ rawRecords.length > 0 ? sortedRecords[0].split(' ')[1] : '???' }} {{ rawRecords.length > 0 ? sortedRecords[0].split(' ')[0] : ''}} из {{ rawRecords.length > 0 ? gradeOfStudents[sortedRecords[0]] : '??' }}</h4>
                        <h4 v-else>Не найден</h4>
                    </div>
                    <div class="card-body">
                        <p class="card-text">Лучший ученик по активности</p>
                        <!-- <div class="text-end">
                            <button class="btn btn-danger">
                                10 <i class="bi bi-heart"></i>
                            </button>
                        </div> -->
                    </div>
                    </div>
                </div>
                <div class="col">
                    <div :class="`card text-bg-${LET_COLORS[getStudentLet(sortedGrades[0])]} mb-3`">
                    <div class="card-header"><h4><i class="bi bi-people"></i> {{sortedGrades[0]}}</h4></div>
                    <div class="card-body">
                        <p class="card-text">Лучший класс по активности</p>
                        <!-- <div class="text-end">
                            <button class="btn btn-danger">
                                7 <i class="bi bi-heart-fill"></i>
                            </button>
                        </div> -->
                    </div>
                    </div>
                </div>
                <div class="col">
                    <div :class="`card text-bg-${LET_COLORS[sortedLets[0]]} mb-3`">
                    <div class="card-header"><h4><i class="bi bi-compass"></i> {{ LET_NAMES[sortedLets[0]] }}</h4></div>
                    <div class="card-body">
                        <p class="card-text">Лучшее направление по активности</p>
                    </div>
                    </div>
                </div>
            </div>
        <!-- </div>
    </div> -->
    <!-- По ученикам -->
    <div class="card">
    <div class="card-body">
        <div class="mb-3">
        <h3 class="text-body">Сортировать по:</h3>
        <form class="form-check">
            <input type="radio" class="btn-check" v-model="sortBy" value="byStudents" name="sortBy" id="byStudents" autocomplete="off" checked>
            <label class="btn btn-outline-primary mx-1 my-1" for="byStudents">По ученикам</label>

            <input type="radio" class="btn-check" v-model="sortBy" value="byNumbers" name="sortBy" id="byNumbers" autocomplete="off">
            <label class="btn btn-outline-primary mx-1 my-1" for="byNumbers">По параллелям</label>

            <input type="radio" class="btn-check" v-model="sortBy" value="byLets" name="sortBy" id="byLets" autocomplete="off">
            <label class="btn btn-outline-primary mx-1 my-1" for="byLets">По направлениям</label>

            <input type="radio" class="btn-check" v-model="sortBy" value="byGrades" name="sortBy" id="byGrades" autocomplete="off">
            <label class="btn btn-outline-primary mx-1 my-1" for="byGrades">По классам</label>
        </form>
    </div>
        <div v-show="sortBy == 'byStudents'" class="mb-10 card">
            <div class="card-body">
            <table class="table">
                <thead>
                    <tr>
                        <th scope="col">#</th>
                        <th scope="col">ФИ</th>
                        <th scope="col">Класс</th>
                        <th scope="col">Кол-во записей</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(name,i) in sortedRecords" :key="i">
                        <th>
                            <p v-if="i>2 || sortedRecords.length < 4">{{ i+1 }}</p>
                            <i v-else-if="i < 3" :class="`bi bi-${i+1}-circle-fill`"></i>
                        </th>
                        <td>{{ name.split(' ')[0] }} {{ name.split(' ')[1].split('')[0] }}.</td>
                        <td>{{ records[name].grade }}</td>
                        <td>{{ records[name].count }}</td>
                    </tr>
                </tbody>
            </table>
            </div>
        </div>
        <!-- По параллелям -->
        <div v-show="sortBy == 'byNumbers'" class="mb-10">
            <h3 class="text-body">По параллелям:</h3>
            <form class="form-check mb-3 bt-3">
                <input type="radio" class="btn-check" v-model="sortNumBy" :value="8" name="sortNumBy" id="gr8" autocomplete="off" checked>
                <label class="btn btn-outline-primary mx-1 my-1" for="gr8">Среди 8-ых</label>

                <input type="radio" class="btn-check" v-model="sortNumBy" :value="9" name="sortNumBy" id="gr9" autocomplete="off">
                <label class="btn btn-outline-primary mx-1 my-1" for="gr9">Среди 9-ых</label>

                <input type="radio" class="btn-check" v-model="sortNumBy" :value="10" name="sortNumBy" id="gr10" autocomplete="off">
                <label class="btn btn-outline-primary mx-1 my-1" for="gr10">Среди 10-ых</label>

                <input type="radio" class="btn-check" v-model="sortNumBy" :value="11" name="sortNumBy" id="gr11" autocomplete="off">
                <label class="btn btn-outline-primary mx-1 my-1" for="gr11">Среди 11-ых</label>
            </form>
            <div class="card">
                <div class="card-body">
                <table class="table">
                    <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">Класс</th>
                            <th scope="col">Кол-во записей</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(grade,i) in sortedRatingByNumbers[`c${sortNumBy}`]" :key="i">
                            <th>
                                <p v-if="i>2 || sortedRatingByNumbers[`c${sortNumBy}`] < 4">{{ i+1 }}</p>
                                <i v-else-if="i < 3" :class="`bi bi-${i+1}-circle-fill`"></i>
                            </th>
                            <td>{{ sortNumBy }}{{ grade }}</td>
                            <td>{{ ratingByNumbers[`c${sortNumBy}`][grade] }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            </div>
        </div>
        <!-- По направлениям -->
        <div v-show="sortBy == 'byLets'" class="card mb-10">
            <div class="card-body">
            <table class="table">
                <thead>
                    <tr>
                        <th scope="col">#</th>
                        <th scope="col">Направление</th>
                        <th scope="col">Кол-во записей</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(name,i) in sortedLets" :key="i">
                        <th>
                            <p v-if="i>2 || sortedLets.length < 4">{{ i+1 }}</p>
                            <i v-else-if="i < 3" :class="`bi bi-${i+1}-circle-fill`"></i>
                        </th>
                        <!-- <th>{{ i == 0 ? '🥇' : i == 1 ? '🥈' : i == 2 ? '🥉' : i+1}}</th> -->
                        <td>{{ LET_NAMES[name] }}</td>
                        <td>{{ ratingByLets[name] }}</td>
                    </tr>
                </tbody>
            </table>
            </div>
        </div>
        <!-- По классам -->
        <div v-show="sortBy == 'byGrades'" class="card mb-3">
            <div class="card-body">
                <table class="table">
                    <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">Класс</th>
                            <th scope="col">Кол-во записей</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(grade,i) in sortedGrades" :key="i">
                            <th>
                            <p v-if="i>2 || sortedGrades.length < 4">{{ i+1 }}</p>
                            <i v-else-if="i < 3" :class="`bi bi-${i+1}-circle-fill`"></i>
                        </th>
                            <td>{{ grade }}</td>
                            <td>{{ recordGrades[grade] }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    </div>
</div>
</template> 

<style>
.bg-secondary{
    border-radius: 0px;
}
</style>