import { createApp } from 'vue'
import { createRouter, createWebHistory } from 'vue-router'
import App from './App.vue'

import FormPageAsync from './components/async/FormPageAsync.vue'
import RatingPageAsync from './components/async/RatingPageAsync.vue'
import MainPage from './components/MainPage.vue'
import NotFound from './components/ui/NotFound.vue'

const router = createRouter({
    history: createWebHistory(),
    routes: [
        {
            name: 'Form',
            path: '/',
            component: FormPageAsync
        },
        {
            name: 'Rating',
            path: '/rating',
            component: RatingPageAsync
        },
        {
            name: 'Main',
            path: '/about',
            component: MainPage
        },
        {
            // path: "*",
            path: "/:catchAll(.*)",
            name: "NotFound",
            component: NotFound,
        }
    ]
})

createApp(App).use(router).mount('#app')
