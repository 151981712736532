
<script setup>
/* eslint-disable */
import { ref, computed, defineProps, defineEmits, watch } from 'vue'
import { getStudents } from '../const.js'

const students = await getStudents()

const { modelValue, invalid } = defineProps({
    modelValue: {
        type: String,
        required: true
    },
    invalid: Boolean
})
const emits = defineEmits(['update:modelValue'])

let studentNamePre = ref(modelValue)
if (studentNamePre.value == undefined) {
    studentNamePre.value = students[0]
    emits('update:modelValue',studentNamePre)
}

// Поиск учеников
let searchStudent = ref('')
const foundedStudents = computed( () => {
    let array = students.filter( (student) => {
        return student.toLowerCase().indexOf(searchStudent.value.toLowerCase()) !== -1
    })
    return array
} )

function searchInput() {
    studentNamePre.value = foundedStudents.value[0]
    emits('update:modelValue',studentNamePre)
}

watch(studentNamePre, () => {
    emits('update:modelValue',studentNamePre)
})

</script>

<template>
    <div class="mb-3">
        <label for="searchStudent">Ваше ФИО:</label>
        <div class="input-group">
            <span class="input-group-text"><i class="bi bi-search"></i></span>
            <input autocomplete="off" id="searchStudent" type="text" :class="`form-control ${foundedStudents.length == 0 ? 'is-invalid' : ''}`" @input="searchInput" v-model="searchStudent" placeholder="Поиск...">
        </div>
    </div>
    <div class="mb-3 input-group">
        <span class="input-group-text" v-if="foundedStudents.length < students.length">{{ foundedStudents.length }}</span>
        <select id="dropdownStudents" :class="`form-select ${invalid ? 'is-invalid' : ''}`" v-model="studentNamePre">
            <option v-for="student in foundedStudents" :key="student" :value="student">{{student}}</option>
        </select>
    </div>
</template>