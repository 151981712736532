<template>
<div class="p-3">
    <div class="main text-body">
        <h1 class="text-center">Система "КПД" для Лицея-интерната 64 </h1>
        <p class="text-center">Мой успех - успех моей страны!</p>
        <div class="card mb-3 p-3">
            <div class="card-body">
                <h2 class="card-title">О системе:</h2>
                КПД - это система отслеживания активности учеников. Фиксируйте свои успехи в форму для приёма заявок.
            </div>
        </div>
        <div class="card mb-3 p-3">
            <div class="card-body">
                <h2 class="card-title">Часто задаваемые вопросы:</h2>
                <div class="accordion" id="qAnswers">
                    <div class="accordion-item">
                        <h2 class="accordion-header" id="headingOne">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-controls="collapseOne">
                            У разных мероприятий разная ценность?
                        </button>
                        </h2>
                        <div id="collapseOne" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#qAnswers">
                        <div class="accordion-body">
                            Количество баллов, которое дают за каждую запись, может варьироваться в зависимости от типа (олимпиада, конкурс и т.д.), уровня (регинальный, международны и т.д.) и итога (победитель, призёр и т.д.) мероприятия
                        </div>
                        </div>
                    </div>
                    <div class="accordion-item">
                        <h2 class="accordion-header" id="headingTwo">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                            Почему рейтинг предворительный?
                        </button>
                        </h2>
                        <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#qAnswers">
                        <div class="accordion-body">
                            Публичный рейтинг составляется путём подсчёта количества записей, которые ещё не успели проверить, и записей, которые успешно прошли проверку.<br><br>
                            Рейтинг может учитывать неверные записи, которые не пройдут проверку, а в зависимости от типа и исхода мероприятия, ценность каждой записи может меняться, которая не учитывается системой рейтинга.
                        </div>
                        </div>
                    </div>
                    <div class="accordion-item">
                        <h2 class="accordion-header" id="headingThree">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                            Что за панель, и почему я не могу туда войти?
                        </button>
                        </h2>
                        <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#qAnswers">
                        <div class="accordion-body">
                            Эта панель администрирования для проверяющих и администараторов. Хотя, вы можете попробовать ввести логин <code>student</code> и пароль <code>Qwerty123</code> 
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="card mb-3 p-3">
            <div class="card-body">
                <h2 class="card-title">Об этом сайте:</h2>
                <p class="mb-2">Сайт создан учениками 9П класса как школьный проект, чтобы облегчить процесс проверки и заполнения записей.</p>
                <h6>Разработчики проекта:</h6>
                <p>
                    <b>Александр Зинкин</b> - общий вид и дизайн сайта. Отвественный за frontend разработку<br>
                    <b>Дмитрий Шинин</b> - отвественный за backend разработку проекта.<br>
                    <b>Мария Васильева</b> - дизайн логотипа
                </p>
                <img src="@/assets/img/logo_light.png" class="rounded" width="160px">
            </div>
        </div>
    </div>
</div>
</template>