<script setup>
/* eslint-disable */
    // import '@/components/bootstrap.bundle.min.js'
    import { ref } from 'vue';
    import { getTheme, themeSwitch } from './components/const';

    const url = window.location.href
    let page = url.split('://')[1].split('/')[1]

    // Data
    const status = ref(page == '' ? 'form' : page == 'about' ? 'main' : page == 'rating' ? 'rating' : '');
    const darkTheme = ref(getTheme())
</script>

<template>
<div :data-bs-theme="darkTheme ? 'dark' : 'light'" :style="`min-height: 100vh;background-color: ${darkTheme ? '#1a1d1f' : '#e0e6e9'};`">
    <nav class="navbar navbar-expand-lg bg-body-tertiary navbar-toggler">
        <div class="container-fluid">
            <RouterLink to="/" tag="div">
                <img v-if="darkTheme" src="@/assets/img/logo_dark.png" class="navbar-brand" v-on:click="status='records'" width="50">
                <img v-else src="@/assets/img/logo_light.png" class="navbar-brand" v-on:click="status='records'" width="50">
            </RouterLink>
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarSupportedContent">
                <ul class="nav nav-pills me-auto mb-2 mb-lg-0">
                    <li class="nav-item">
                        <RouterLink tag="p" v-on:click="status='main'" to="/about" :class="`nav-link ${status == 'main' ? 'active' : ''}`">Главная</RouterLink>
                    </li>
                    <li class="nav-item">
                        <RouterLink tag="p" v-on:click="status='rating'" to="/rating" :class="`nav-link ${status == 'rating' ? 'active' : ''}`">Рейтинг</RouterLink>
                    </li>
                    <li class="nav-item">
                        <RouterLink tag="p" v-on:click="status='form'" to="/" :class="`nav-link ${status == 'form' ? 'active' : ''}`">Форма</RouterLink>
                    </li>
                    <li class="nav-item">
                        <p class="nav-link"><br></p>
                    </li>
                </ul>
                <button type="button" v-if="darkTheme" :class="`btn btn-dark d-flex mx-1 my-1`" @click="themeSwitch()"><i class="bi bi-sun"></i></button>
                <button type="button" v-else :class="`btn btn-light d-flex mx-1 my-1`" @click="themeSwitch()"><i class="bi bi-moon"></i></button>
                <a href="https://panel.kpd64.ru" class="btn"><button type="button" :class="`btn btn-${darkTheme ? 'dark' : 'light'} d-flex mx-1 my-1`"><i class="bi bi-box-arrow-up-right"></i>&nbsp;Панель</button></a>
            </div>
        </div>
    </nav>
    <div class="pt-2">
        <RouterView/>
        <!-- <RouterView v-slot="{ Component }">
            <template v-if="Component">
                <KeepAlive>
                    <Suspense>
                        <component :is="Component"/>
                        <template #fallback>
                            <div class="d-flex justify-content-center m-5">
                                <div class="spinner-border text-primary" role="status">
                                    <span class="visually-hidden">Loading...</span>
                                </div>
                            </div>
                        </template>
                    </Suspense>
                </KeepAlive>
            </template>
        </RouterView> -->
        <!-- <Suspense>
            <template #default><RouterView/></template>
            <template #fallback>
                <div class="d-flex justify-content-center m-5">
                    <div class="spinner-border text-primary" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                </div>
            </template>
        </Suspense> -->
    </div>
    <!-- <div class="pt-2">
      <div v-if="status=='main'">
          <MainPage/>
      </div>
      <div v-if="status=='rating'">
        <Suspense>
            <RatingPage/>
            <template #fallback>
                <div class="d-flex justify-content-center m-5">
                    <div class="spinner-border text-primary" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                </div>
            </template>
        </Suspense>
      </div>
      <div v-if="status=='form'">
        <Suspense>
            <SendRecordForm/>
            <template #fallback>
                <div class="d-flex justify-content-center m-5">
                    <div class="spinner-border text-primary" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                </div>
            </template>
        </Suspense>
      </div>
  </div> -->
  <div class="footer">
      
  </div>
</div>
</template>

<style>
@import url('https://cdn.jsdelivr.net/npm/bootstrap@5.3.2/dist/css/bootstrap.min.css');
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.11.2/font/bootstrap-icons.min.css");
img{
    cursor: pointer;
}
.nav-link{
    cursor: pointer;
}
.form-check-input{
    cursor: pointer;
}
.nav{
    padding-top: 15px;
}

</style>