<script setup>
/* eslint-disable*/
    import { ref, computed } from 'vue'
    import axios from 'axios'
    import { CLASS_LET, CLASS_NUM } from './const.js'
    import UIstudentSelect from './ui/UIstudentSelect.vue';
    import AlertMessage from './ui/AlertMessage.vue';
    import jsCookie from 'js-cookie';
    import { httpGet, httpResponse } from './func/request';

    const isClosed = ref((await httpGet('data/closed.json',true,true,true)).data)
    const GRADES = ref([])
    for (let num of CLASS_NUM) {
        for (let lett of CLASS_LET) {
            GRADES.value.push(`${num}${lett}`)
        }
    }

    let cookieGrade = jsCookie.get('studentGrade')
    let cookieStudent = jsCookie.get('studentName')
    if (!cookieGrade) cookieGrade = GRADES.value[0]

    const formDataBlank = { // Это пустой бланк
        eventType: 0,
        eventName: '',
        eventLevel: null,
        eventDate: '',
        eventResult: null,
        fileName: '',
        studentGrade: cookieGrade,
        studentName: cookieStudent,
    }

    let fileProof = ref(null) // Здесь будет храниться файл
    const formData = ref({ // Ну а здесь данные формы
        eventType: 0,
        eventName: '',
        // eventCount: 1,
        eventDate: '',
        eventLevel: null,
        eventResult: null,
        fileName: '',
        studentGrade: cookieGrade,
        studentName: cookieStudent,
    })

    const hiddenElements = ref({
        successAlert: false,
        errorAlert: false,
        lockButton: false
    })

    // Отправка заполенной формы
    async function sendData() {
        hiddenElements.value.errorAlert = false
        hiddenElements.value.successAlert = false
        let hasErrors = false
        hiddenElements.value.lockButton = true

        // Сохранение класса и имени студента... ДА КАКОГО СТУДЕНТА??? ученика.
        jsCookie.set('studentName',formData.value.studentName, { expires: 365 })
        jsCookie.set('studentGrade',formData.value.studentGrade, { expires: 365 })
        
        // Отправка файла
        const formData2 = new FormData();
        formData2.append('file', fileProof.value);
        await axios.post('https://api.kpd64.ru/storage/save.php', formData2, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
        .then(response => {
            // Файл отправлен на сервер
            formData.value.fileName = response.data.data
        })
        .catch(error => {
            hasErrors = true
            console.error(error);
        });
        formData.value.eventType = parseInt(formData.value.eventType)
        let responseData = await httpResponse('records/save.php',formData.value,true,false)
        if (!responseData.isValid) hasErrors = true        
        hiddenElements.value.lockButton = false
        if (hasErrors) {
            hiddenElements.value.errorAlert = true
        } else { // Успех (в жизни)
            hiddenElements.value.successAlert = true
            fileProof.value = null
            Object.assign(formData.value,formDataBlank)
            formData.value.studentGrade = jsCookie.get('studentGrade')
            formData.value.studentName = jsCookie.get('studentName')
        }
    }

    //Загрузка файлов
    function photoUpload(e) {
        const [file] = e.target.files
        fileProof.value = file
    }
    let photoPath = computed( () => {
        const file = fileProof.value
        if (file) {
            // const fileName = file.name
            // const fileExt = fileName.split('.')[fileName.split('.').length-1]
            if (file.type.split('/')[0] == 'image') {
                return URL.createObjectURL(file)
            }
            // } else if (fileExt == 'docx' || fileExt == 'doc' || fileExt == 'odt') {
            //     return require('@/assets/img/file_docx.png')
            // } else if (fileExt == 'pdf') {
            //     return require('@/assets/img/file_pdf.png')
            // } else if (fileExt == 'xls' || fileExt == 'xlsx') {
            //     return require('@/assets/img/file_xls.png')
            // }
        }
        return require('@/assets/img/micropic.png')
    } )

    // Переменные классов полей, у которых есть валидация
    let validatorTagsBlank = {
        dateInput: '',
        eventNameInput: '',
        fileInput: '',
        // countInput: '',
        eventResultRadio: '',
        eventLevelRadio: '',
        fileInputSize: false,
        studentName: false,
        invalidAlert: false
    }
    let validatorTags = ref({})
    Object.assign(validatorTags.value,validatorTagsBlank)

    // Функция при отправке (проверяет достоверность заполненных полей)
    function trySendData() {
        let isValid = true
        validatorTags.value.invalidAlert = false
        Object.assign(validatorTags.value,validatorTagsBlank)
        let formValues = formData.value
        if (formValues.eventDate == '') {
            validatorTags.value.dateInput = 'is-invalid'
            isValid = false
        }
        if (formValues.eventName == '') {
            validatorTags.value.eventNameInput = 'is-invalid'
            isValid = false
        }
        if (fileProof.value === null) {
            validatorTags.value.fileInput = 'is-invalid'
            isValid = false
        }
        if (fileProof.value && fileProof.value.size > 10485760) {
            validatorTags.value.fileInput = 'is-invalid'
            validatorTags.value.fileInputSize = true
            isValid = false
        }
        if (formValues.eventResult === null) {
            validatorTags.value.eventResultRadio = 'is-invalid'
            isValid = false
        }
        if (formValues.eventLevel === null) {
            validatorTags.value.eventLevelRadio = 'is-invalid'
            isValid = false
        }
        // if (formValues.eventCount === '') {
        //     validatorTags.value.countInput = 'is-invalid'
        //     isValid = false
        // }
        if (formValues.studentName == undefined) {
            validatorTags.value.studentName = true
            isValid = false
        }
        if (!isValid) {
            validatorTags.value.invalidAlert = true
            return
        }
        formValues.eventType = parseInt(formValues.eventType)
        sendData()
    }
</script>

<template>
<div class="container pt-3 pb-3">
    <Suspense>
            <AlertMessage/>
    </Suspense>
    <form @submit.prevent="trySendData" class="form-control p-3">
        <div class="alert alert-danger" role="alert" v-show="isClosed">
            Форма временно закрыта администратором!
        </div>
        <h2>Мероприятие</h2>
        <div class="mb-3">
            <label for="eventType">Тип мероприятия:</label>
            <select id="eventType" v-model="formData.eventType" class="form-select" :disabled="isClosed">
                <option value="0">Всероссийская олимпиада школьников (учащиеся)</option>
                <option value="1">Научно-практические конференции (учащиеся)</option>
                <option value="2">Заочные и дистанционные конкурсные мероприятия (учащиеся)</option>
                <option value="3">Очные интеллектуальные конкурсные мероприятия (учащиеся)</option>
                <option value="4">Мероприятия и конкурсы художественно-эстетической направленности (учащиеся)</option>
                <option value="5">Спортивные состязания (учащиеся)</option>
                <option value="6">Социально-значимые мероприятия</option>
                <option value="7">Военно-патриотические, экологические, туристско-краеведческие, поисковые мероприятия (учащиеся)</option>
            </select>
        </div>

        <div class="mb-3">
            <label for="eventName">Название мероприятия:</label>
            <input type="text" id="eventName" :class="`form-control ${validatorTags.eventNameInput}`" v-model="formData.eventName" :disabled="isClosed">
        </div>

        <!-- <div class="mb-3">
            <label for="eventCount"  aria-describedby="countMoreInfo">Количество участников:</label>
            <input type="number" :class="`form-control ${validatorTags.countInput}`" id="eventCount" v-model="formData.eventCount" placeholder="10" min="1" :disabled="isClosed">
            <div id="eventCount" class="form-text">Если в мероприятии участвовал коллектив, группа и т.п., то указывается количество коллективов</div>
        </div> -->

        <div class="mb-3">
            <label for="eventDate">Дата проведения:</label>
            <input id="eventDate" :class="`form-control ${validatorTags.dateInput}`" type="date" v-model="formData.eventDate" :disabled="isClosed">
        </div>
        <div class="mb-3 form-check">
            <label>Уровень:</label>
            <div class="input-radio">
                <div><input :disabled="isClosed" :class="`form-check-input ${validatorTags.eventLevelRadio}`" type="radio" id="school" :value="0" v-model="formData.eventLevel" name="eventLevel"><label class="form-check-label" for="school">Школьный</label></div>
                <div><input :disabled="isClosed" :class="`form-check-input ${validatorTags.eventLevelRadio}`" type="radio" id="city" :value="1" v-model="formData.eventLevel" name="eventLevel"><label class="form-check-label" for="city">Муниципальный</label></div>
                <div><input :disabled="isClosed" :class="`form-check-input ${validatorTags.eventLevelRadio}`" type="radio" id="region" :value="2" v-model="formData.eventLevel" name="eventLevel"><label class="form-check-label" for="region">Региональный</label></div>
                <div><input :disabled="isClosed" :class="`form-check-input ${validatorTags.eventLevelRadio}`" type="radio" id="country" :value="3" v-model="formData.eventLevel" name="eventLevel"><label class="form-check-label" for="country">Всероссийский</label></div>
                <div><input :disabled="isClosed" :class="`form-check-input ${validatorTags.eventLevelRadio}`" type="radio" id="world" :value="4" v-model="formData.eventLevel" name="eventLevel"><label class="form-check-label" for="world">Международный</label></div>
            </div>
        </div>
        <div class="mb-3 form-check">
            <label>Результат:</label>
            <div class="input-radio">
                <div><input :disabled="isClosed" :class="`form-check-input ${validatorTags.eventResultRadio}`" type="radio" id="winner" :value="0" v-model="formData.eventResult" name="eventResult"><label class="form-check-label" for="winner">Победитель</label></div>
                <div><input :disabled="isClosed" :class="`form-check-input ${validatorTags.eventResultRadio}`" type="radio" id="prize" :value="1" v-model="formData.eventResult" name="eventResult"><label class="form-check-label" for="prize">Призёр</label></div>
                <div><input :disabled="isClosed" :class="`form-check-input ${validatorTags.eventResultRadio}`" type="radio" id="member" :value="2" v-model="formData.eventResult" name="eventResult"><label class="form-check-label" for="member">Участник</label></div>
                <div><input :disabled="isClosed" :class="`form-check-input ${validatorTags.eventResultRadio}`" type="radio" id="organizer" :value="3" v-model="formData.eventResult" name="eventResult"><label class="form-check-label" for="organizer">Организатор (если социально-значимое мероприятие организовывали ВЫ)</label></div>
            </div>
        </div>
        <div class="mb-3">
            <label for="photoProof" class="form-label">Подтверждение участия (грамота, сертификат и т.д.) (Не более 10МБ)</label>
            <!-- application/msword, application/vnd.ms-excel, .docx, application/pdf image/*-->
            <input :disabled="isClosed" :class="`form-control ${validatorTags.fileInput}`" type="file" name="photoProof" id="photoProof" accept=".jpg, .jpeg, .png" @change="photoUpload">
        </div>
        <div class="alert alert-danger" role="alert" v-if="validatorTags.fileInputSize">
            Размер загруженного файла превышает допустимый лимит!
        </div>
        <div class="mb-3" v-if="Boolean(fileProof)"><img class="rounded documentPreview" :src="photoPath" alt='preview'></div>
        <h2>Ваши данные</h2>
        
        <div class="mb-3">
            <label for="studentGrade">Ваш класс:</label>
            <select :disabled="isClosed" class="form-select" id="dropdownGrades" v-model="formData.studentGrade">
                <option v-for="grade in GRADES" :key="grade" :value="grade">{{grade}}</option>
            </select>
        </div>
        <Suspense>
            <UIstudentSelect v-model="formData.studentName" :invalid="validatorTags.studentName"/>
            <template #fallback>
                    <p>Загружаем список учеников...<br></p>
            </template>
        </Suspense>
        <button type="submit" class="btn btn-primary btn-lg" :disabled="hiddenElements.lockButton || isClosed"><span class="spinner-border spinner-border-sm" aria-hidden="true" v-if="hiddenElements.lockButton"></span>{{hiddenElements.lockButton ? ' Отправляем данные...': isClosed ? 'Форма закрыта' : 'Отправить на проверку'}}</button>
        <div class="alert alert-primary mt-3" role="alert" v-if="hiddenElements.lockButton">Отправляем данные... Не закрывайте браузер!</div>
        <div class="alert alert-success mt-3" role="alert" v-if="hiddenElements.successAlert">Ваша запись успешно отправлена на проверку!</div>
        <div class="alert alert-danger mt-3" role="alert" v-if="hiddenElements.errorAlert">Не удалось отправить форму. Возника ошибка на серверной стороне.</div>
        <div class="alert alert-danger mt-3" role="alert" v-if="validatorTags.invalidAlert">Найдены ошибки в некоторых полях!</div>
    </form>
</div>
</template>

<style>
    .documentPreview {
        max-width: 150px;
        max-height: 150px;
    }
</style>