import jsCookie from 'js-cookie';
import { httpGet } from './func/request';

export const CLASS_LET = ['П','Р','К','М','Б']
export const CLASS_NUM = ['8', '9', '10', '11']
export const LET_NAMES = {
    'П': 'Программирование',
    'Р': 'Робототехника',
    'К': 'Культура',
    'М': 'Медицина будущего',
    'Б': 'Биотехнология'
}

export const LET_COLORS = {
    'П': 'success',
    'Р': 'success',
    'К': 'warning',
    'М': 'info',
    'Б': 'primary'
}

export function getTheme() {
    if (jsCookie.get('theme') != undefined) return jsCookie.get('theme') == 'true'
    if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
        return true
    }
    return false
}

export function themeSwitch() {
    jsCookie.set('theme',!getTheme(),{expires:365})
    location.reload();
}

export function getStudentLet(grade) {
    if (grade.length == 2) {
        return grade[1]
    }
    return grade[2]
}

export function getStudentNum(grade) {
    if (grade.length == 2) {
        return grade[0]
    }
    return parseInt(`1${grade[1]}`)
}

export async function getStudents() {
    let version = (await httpGet('data/students_version.json',true,'0',true)).data
    let array = (await httpGet(`data/students.json?v=${version}`,true,[])).data
    return array
}