<script setup>
import { ref } from 'vue'
import { httpGet } from '../func/request';

const alertMessageRaw = (await httpGet('data/alert.json',true,{},true)).data
const alertMessage = ref(alertMessageRaw.text)
const alertMessageColor = ref(alertMessageRaw.color)
const alertMessageButtonLink = ref(alertMessageRaw.buttonLink)
const alertMessageButtonText = ref(alertMessageRaw.buttonText)

const showAlertMessage = ref(Boolean(alertMessage.value))
const showAlertButton = ref(Boolean(alertMessageButtonText.value))

</script>

<template>
    <div :class="`alert alert-${alertMessageColor}`" v-if="showAlertMessage">
        <div>{{ alertMessage }}</div>
        <a :href="alertMessageButtonLink" target="_blank"><button v-if="showAlertButton" :class="`mt-3 btn btn-${alertMessageColor}`"><i class="bi bi-box-arrow-up-right"></i> {{ alertMessageButtonText }}</button></a>
    </div>
</template>